<script setup lang="ts">
import type { ResponsiveImageType } from "vue-datocms";
import type { SplitItemRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const props = defineProps<{ data: SplitItemRecord }>();

const localePath = useLocalePath();
const link = computed(() => getLinkForType(props.data.optLinkItem, localePath));
</script>

<template>
  <div class="gap-xl grid grid-cols-1 md:grid-cols-2">
    <div :class="{ 'order-1 md:order-1': data.imagePosition, 'order-1 md:order-2': !data.imagePosition }">
      <DatocmsImage v-if="data.image" :data="data.image.responsiveImage as ResponsiveImageType" class="rounded-sm" />
    </div>
    <div :class="{ 'order-2 md:order-1': !data.imagePosition, 'order-2 md:order-2': data.imagePosition }" class="flex items-center">
      <div class="html-content">
        <div v-html="data.text" />

        <NuxtLinkLocale v-if="link.to" :to="link.to" :target="link.target">
          {{ link.label }}
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
