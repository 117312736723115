<!-- eslint-disable antfu/top-level-function -->

<script setup lang="ts">
import { StructuredButton, StructuredSplit } from "#components";
import { Image as DatocmsImage, StructuredText as DatocmsStructuredText } from "vue-datocms";

const props = defineProps({
  data: {} as any,
});

// https://www.datocms.com/docs/nuxt/rendering-structured-text-fields
// https://www.datocms.com/docs/structured-text/dast#block
// https://www.datocms.com/docs/nuxt/managing-images
const renderBlock = ({ record }: { record: any }) => {
  if (record._modelApiKey === "button") {
    return h("div", { class: "mb-xl" }, [h(StructuredButton, { data: record })]);
  }

  if (record._modelApiKey === "image_block" || record.__typename === "SingleImageRecord") {
    return h("div", { class: "mb-xl" }, [h(DatocmsImage, { data: record.image.responsiveImage })]);
  }

  if (record._modelApiKey === "split_item") {
    // use a kind of *-bleed, only for large desktop screens
    return h("div", { class: "mb-xl xl:w-screen xl:max-w-[1200px] xl:left-1/2 xl:right-1/2 xl:mx-[-600px] xl:relative" }, [h(StructuredSplit, { data: record })]);
  }

  return h(
    "div",
    {},
    [
      h("p", {}, "Don't know how to render a block!"),
      h("pre", {}, JSON.stringify(record, null, 2)),
    ],
  );
};
</script>

<template>
  <div class="structured-content">
    <DatocmsStructuredText :data="props.data" :render-block="renderBlock" />
  </div>
</template>

<style scoped>

</style>
